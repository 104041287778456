import React from "react"
import Loadable from "@loadable/component"
// Style Imports
import './styles.scss'
// SEO
import Seo from '@components/utility/SEO'
// Structural Imports
import Layout from "@components/structure/Layout.jsx"
// Content Imports
import Hero from "@components/content/hero/Hero.jsx"
import Image from "@components/content/fractals-image.jsx"
import DoctorDiscussionGuideCTA from "@components/content/DoctorDiscussionGuide/DoctorDiscussionGuide.jsx"
import PatientFeedbackWhy from "@components/content/patientFeedback/page-why/PatientFeedbackWhy"
const Cta = Loadable(() => import('@components/content/cta/CTA'))
const GroupIcon = Loadable(() => import('@components/content/icons/Group.jsx'))
const KidneyLargeIcon = Loadable(() => import('@components/content/icons/KidneyLarge.jsx'))
const LowerIcon = Loadable(() => import('@components/content/icons/Lower.jsx'))
const YogaLargeIcon = Loadable(() => import('@components/content/icons/YogaLarge.jsx'))

const whyLupkynis = () => (
    <Layout addedClass="page--why-lupkynis">
        <Seo title="Why LUPKYNIS&reg; (voclosporin)?" description="LUPKYNIS is helping to transform the way lupus nephritis is treated. See clinical results and learn about the benefits of taking LUPKYNIS. See full Safety and Prescribing Information, and BOXED WARNING." canonicalURL="https://www.lupkynis.com/why-lupkynis" />
        <Hero addedClass="bg-green hero--string">
            <div className="col-wrapper">
                <div className="container">
                    <div className="hero__content">
                        <h1 className="heading heading--accent">Why Lupkynis?</h1>
                        <h2>A treatment that helps you <span>take on the challenge</span></h2>
                    </div>
                    <div className="hero__image">
                        <Image data={{desktopSrc: 'why-lupkynis/hero.png', mobileSrc: 'why-lupkynis/hero--mobile.png', altText:"Image of Malisha, started treatment in 2021"}} />
                    </div>
                </div>
            </div>
        </Hero>

        <section id="ClinicalTrials" className="bg-purple">
            <div className="container container--narrow">
                <div className="text-center">
                    <h2>LUPKYNIS is helping to transform the way lupus nephritis is treated</h2>
                </div>
                <div>
                    <div className="icon-callout">
                        <div className="icon">
                            <YogaLargeIcon />
                        </div>
                        <div className="copy-block">
                            <h4>Helps control lupus nephritis</h4>
                            <p>For people taking LUPKYNIS<sup>a</sup>, the chance of getting their lupus nephritis under control<sup>b</sup> after one year of treatment was almost 3 times greater<sup>c</sup> than for people only taking mycophenolate mofetil (MMF) and steroids.</p>
                        </div>
                    </div>
                    <div className="icon-callout">
                        <div className="icon">
                            <LowerIcon />
                        </div>
                        <div className="copy-block">
                            <h4>Lowered the dose of steroids needed</h4>
                            <p>In clinical trials, LUPKYNIS helped to stop the attack of lupus nephritis with low-dose steroids.</p>
                        </div>
                    </div>
                    <div className="icon-callout">
                        <div className="icon">
                            <KidneyLargeIcon />
                        </div>
                        <div className="copy-block">
                            <h4>Reduces protein in urine</h4>
                            <p>In clinical trials, LUPKYNIS helped reduce protein in the urine (called proteinuria, a key sign of disease activity) roughly 2x faster<sup>d</sup> than MMF and steroids alone.</p>
                            <p>People taking LUPKYNIS saw a 50% reduction of proteinuria, on average after just one month.</p>
                        </div>
                    </div>
                    <div className="icon-callout">
                        <div className="icon">
                            <GroupIcon />
                        </div>
                        <div className="copy-block">
                            <h4>Studied across different races and ethnicities</h4>
                            <p>LUPKYNIS was tested in clinical studies that involved more than 350&nbsp;people across different races and ethnicities.</p>
                        </div>
                    </div>
                </div>
                <div className="footnote">
                    <p><sup>a</sup>As part of a treatment plan that included mycophenolate mofetil and steroids.</p>
                    <p><sup>b</sup>Control was defined as reducing protein in the urine to &le;0.5&nbsp;mg/mg, while helping maintain kidney function.</p>
                    <p><sup>c</sup>The odds ratio was 2.7.</p>
                    <p><sup>d</sup>On average after six months of treatment compared to a year for patients only taking MMF and steroids.</p>
                </div>
                <div className="text-center">
                    <Cta url="/taking-lupkynis">What to expect when taking LUPKYNIS</Cta>
                </div>
            </div>
        </section>
        <PatientFeedbackWhy />
        <DoctorDiscussionGuideCTA />
    </Layout>
)

export default whyLupkynis